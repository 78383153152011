<template>
    <div class="pageWrapper">
        <!--div class="pageLeft">
            <div class="inner">
                <div class="pageAction">
                    <router-link class="brand" to="/">
                        <img src="@/assets/images/logo.svg" />
                    </router-link>
                </div>
                
                <div class="styledHeading">
                    <h1>Hello</h1>
                </div>
                <p>I am KinHang a UI Designer from Malaysia. My daily life is to deal the pixels and vector to come out with a presentatable and friendly solution.</p>


                <div class="styledHeading">
                    <h2>Skills</h2>
                </div>
                <ul class="skills">
                    <li v-for="skill in skills" :key="skill.id">
                        <template v-if="skill.id == 4"><img src="@/assets/images/skills/photoshop.svg" class="icon"></template>
                        <template v-if="skill.id == 2"><img src="@/assets/images/skills/illustrator.svg" class="icon"></template>
                        <template v-if="skill.id == 3"><img src="@/assets/images/skills/xd.svg" class="icon"></template>
                        <template v-if="skill.id == 1"><img src="@/assets/images/skills/figma.svg" class="icon"></template>
                        <template v-if="skill.id == 5"><img src="@/assets/images/skills/html_css.svg" class="icon"></template>
                        <template v-if="skill.id == 6"><img src="@/assets/images/skills/laravel.svg" class="icon"></template>
                        <template v-if="skill.id == 7"><img src="@/assets/images/skills/vue.svg" class="icon"></template>

                        <div class="details">
                            <h5 class="title">{{skill.skillName}}</h5>
                            <label>{{skill.experience}}</label>
                            <div class="progress">
                                <div class="progressBar" :style="{  'max-width': skill.percentage  }"></div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="pageRight">
            <div class="inner">
                <div class="styledHeading">
                    <h2>Works</h2>
                </div>

                <div class="project">
                    <div class="projectItem" v-for="project in projects" :key="project.id">
                        <router-link :to="project.url" class="inner">
                            <div class="projectThumb">
                        <template v-if="project.id == 12"><img src="@/assets/images/works/tourplus/cover.jpg"></template>
                        <template v-if="project.id == 9"><img src="@/assets/images/works/bluemount/cover.jpg"></template>
                        <template v-if="project.id == 11"><img src="@/assets/images/works/traworld/cover.jpg"></template>
                        <template v-if="project.id == 10"><img src="@/assets/images/works/trading/cover.jpg"></template>
                        <template v-if="project.id == 8"><img src="@/assets/images/works/bbb/cover.jpg"></template>
                        <template v-if="project.id == 0"><img src="@/assets/images/works/bitpays/cover.jpg"></template>
                        <template v-if="project.id == 1"><img src="@/assets/images/works/trexchain/cover.jpg"></template>
                        <template v-if="project.id == 2"><img src="@/assets/images/works/w/cover.jpg"></template>
                        <template v-if="project.id == 3"><img src="@/assets/images/works/cloudtac/cover.jpg"></template>
                        <template v-if="project.id == 4"><img src="@/assets/images/works/mifun/mifun.jpg"></template>
                        <template v-if="project.id == 6"><img src="@/assets/images/works/stuttgart/stuttgart.jpg"></template>
                            </div>
                            <div class="projectDetails">
                                <h4 class="title">{{project.name}}</h4>
                                <p>{{project.desc}}</p>
                            </div>
                        </router-link>
                    </div>

                </div>
            </div>
        </div-->

        <div class="home">
            <section class="homeTop">
                <div class="container">
                    
                    <div class="pageAction">
                        <router-link class="brand" to="/">
                            <img src="@/assets/images/logo.svg" />
                        </router-link>
                    </div>

                    <div class="homeTopContent">
                        <div class="styledHeading">
                            <h1>Hello</h1>
                        </div>
                        <div class="text-zhcn">你好</div>
                        <p>I am KinHang a UI UX Designer from Malaysia. My daily task is to deal the pixels and vector to come out with a presentable and friendly solution. I have some knowledge in front-end developerment as in like HTML, CSS and Javascript.</p>
                    </div>

                    <div class="styledHeading mt-6">
                        <h4>Skills</h4>
                    </div>

                    <div class="skillContainer">
                        <div class="item" v-for="skill in skills" :key="skill.id">
                            <template v-if="skill.id == 4"><img src="@/assets/images/skills/photoshop.svg" class="icon"></template>
                            <template v-if="skill.id == 2"><img src="@/assets/images/skills/illustrator.svg" class="icon"></template>
                            <template v-if="skill.id == 3"><img src="@/assets/images/skills/xd.svg" class="icon"></template>
                            <template v-if="skill.id == 1"><img src="@/assets/images/skills/figma.svg" class="icon"></template>
                            <template v-if="skill.id == 5"><img src="@/assets/images/skills/html_css.svg" class="icon"></template>
                            <template v-if="skill.id == 6"><img src="@/assets/images/skills/laravel.svg" class="icon"></template>
                            <template v-if="skill.id == 7"><img src="@/assets/images/skills/vue.svg" class="icon"></template>

                            <div class="details">
                                <h5 class="title">{{skill.skillName}}</h5>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section class="sectionGrey">
                <div class="container">

                    <div class="styledHeading">
                        <h2>Past Work</h2>
                    </div>
                    <p>Showcase of some my past work.</p>

                    <div class="projectContainer">
                        <div class="item" v-for="project in projects" :key="project.id">
                            <router-link :to="project.url">
                                <template v-if="project.id == 12"><img src="@/assets/images/works/tourplus/tp_cover.png"></template>
                                <template v-if="project.id == 11"><img src="@/assets/images/works/traworld/tw_cover.png"></template>
                                <template v-if="project.id == 0"><img src="@/assets/images/works/bitpays/bitpays_cover.png"></template>
                            </router-link>
                        </div>

                    </div>

                </div>
            </section>

        </div>

        <div class="footer">
            <div class="container text-center">
                
                <div class="styledHeading">
                    <h3>Let's connect</h3>
                </div>
                <p>Get in touch for opportunities or just to say hi! 👋</p>

                <div class="action">
                    <a href="https://www.linkedin.com/in/kinhang-aw-455185181" target="_blank"><img src="@/assets/images/icon_linkedin.svg"></a>
                    <!--a href=""><img src="@/assets/images/icon_envelope.svg"></a-->
                </div>
            </div>
        </div>

    </div>
</template>

<script> 
    export default {
        name: 'home',
        data () {
            return {
                skills: [
                    {
                        id: '1',
                        skillName: 'Figma',
                        experience: 'Upper Average',
                        percentage: '75%'
                    },
                    {
                        id: '3',
                        skillName: 'Adobe XD',
                        experience: 'Upper Average',
                        percentage: '75%'
                    },
                    {
                        id: '2',
                        skillName: 'Illustrator',
                        experience: 'Upper Average',
                        percentage: '75%'
                    },
                    {
                        id: '4',
                        skillName: 'Photoshop',
                        experience: 'Average',
                        percentage: '50%'
                    },
                    {
                        id: '5',
                        skillName: 'HTML & CSS',
                        experience: 'Upper Average',
                        percentage: '75%'
                    },
                    /*{
                        id: '6',
                        skillName: 'Laravel',
                        experience: 'Beginner',
                        percentage: '25%'
                    },
                    {
                        id: '7',
                        skillName: 'Vue.js',
                        experience: 'Beginner',
                        percentage: '25%'
                    }*/
                ],

                projects:[
                    /*{
                        id: '7',
                        name: 'GoBao',
                        desc: 'Logo design, UI & UX Design',
                        url: '/works/gobao'
                    },*/
                    {
                        id: '12',
                        name: 'Tourplus',
                        desc: 'UI & UX design',
                        url: '/works/tourplus'
                    },
                    {
                        id: '11',
                        name: 'Traworld Merchant',
                        desc: 'UI & UX design',
                        url: '/works/traworld'
                    },
                    /*{
                        id: '9',
                        name: 'BlueMount',
                        desc: 'UI design, Front-end Developerment',
                        url: '/works/bluemount'
                    },
                    {
                        id: '10',
                        name: 'Trading App Case Study',
                        desc: 'UI & UX Design',
                        url: '/works/trading'
                    },
                    {
                        id: '8',
                        name: 'BBB Superapp',
                        desc: 'UI & UX Design',
                        url: '/works/bbbsuperapp'
                    },*/
                    {
                        id: '0',
                        name: 'Bitpays',
                        desc: 'Logo Design, UI & UX Design',
                        url: '/works/bitpays'
                    },
                    /*{
                        id: '1',
                        name: 'TrexChain',
                        desc: 'UI design & development',
                        url: '/works/trexchain'
                    },
                    {
                        id: '9',
                        name: 'RedCliff',
                        desc: 'UI Design & Development',
                        url: '/works/redcliff'
                    },
                    {
                        id: '3',
                        name: 'CloudTac',
                        desc: 'Logo design, UI & UX Design',
                        url: '/works/cloudtac'
                    },
                    {
                        id: '2',
                        name: 'W Entertechment',
                        desc: 'Web design, Coin Design',
                        url: '/works/wentertechment'
                    },
                    {
                        id: '4',
                        name: 'MiFun Network',
                        desc: 'Branding & web design',
                        url: '/works/mifun'
                    },
                    {
                        id: '6',
                        name: 'Stuttgart',
                        desc: 'Social media post design',
                        url: '/works/stuttgart'
                    },*/
                ]
            }
        },
    }
</script>

<style scoped>
</style>
